body {
  background-image: url("bg2.c9a91dba.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

.p-main {
  display: block;
}

.p-main .p-top {
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  color: #fff;
  background-color: #00000080;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.p-main .p-top .p-top-button {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 5px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
  display: inline-flex;
}

.p-main .p-top .p-top-button:hover {
  background-color: #fff3;
}

.p-main .p-top .p-logo {
  text-shadow: 0 2px 2px #000;
  align-items: center;
  padding-bottom: 2px;
  font-family: Agdasima, sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 100%;
  display: flex;
}

.p-main .p-top .p-logo img {
  height: 24px;
  margin-bottom: -1px;
  margin-right: 6px;
  transform: rotate(35deg);
}

.p-main .p-canvas {
  -webkit-user-select: none;
  user-select: none;
  display: block;
}

.p-main .p-explorer {
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  color: #fff;
  background-color: #0000004d;
  border: 1px solid #00000080;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
  max-height: 80vh;
  padding: 8px;
  font-family: Ubuntu, sans-serif;
  font-size: 12pt;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-y: scroll;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 15px #00000080;
}

.p-main .p-explorer .p-logo {
  text-shadow: 0 2px 2px #000;
  justify-content: center;
  align-items: center;
  margin: 0 auto 12px;
  padding-bottom: 2px;
  font-family: Agdasima, sans-serif;
  font-size: 48px;
  font-weight: bold;
  line-height: 100%;
  display: flex;
}

.p-main .p-explorer .p-logo img {
  height: 48px;
  margin-bottom: -1px;
  margin-right: 6px;
  transform: rotate(35deg);
}

.p-main .p-explorer p {
  margin: 0 0 8px;
  padding: 0;
}

.p-main .p-explorer .p-explorer-puzzle {
  box-sizing: border-box;
  align-items: center;
  padding: 4px;
  display: flex;
}

.p-main .p-explorer .p-explorer-puzzle:nth-child(2n) {
  background-color: #ffffff1a;
}

.p-main .p-explorer .p-explorer-puzzle:nth-child(3n), .p-main .p-explorer .p-explorer-puzzle:first-child {
  background-color: #ffffff4d;
}

.p-main .p-explorer .p-explorer-puzzle h4 {
  margin: 0 0 8px;
  font-family: Agdasima, sans-serif;
  font-size: 24px;
  font-weight: bold;
  display: block;
}

.p-main .p-explorer .p-explorer-puzzle img {
  border-radius: 8px;
  width: 100%;
  max-width: 150px;
  margin-right: 8px;
  display: block;
}

.p-button {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  background-color: #00000080;
  border: 1px solid #00000080;
  border-radius: 5px;
  outline: none;
  padding: 4px 8px;
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
  display: inline-block;
}

.p-button:hover {
  border: 1px solid #fff;
}

.p-button.continue {
  text-transform: uppercase;
  border-color: #00f;
  font-size: 11px;
  font-weight: bold;
  box-shadow: inset 0 0 5px #00bfff;
}

.p-button.continue:hover {
  filter: brightness(150%);
}

i[class^="ri-"] {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  margin-top: -.2em;
  font-size: 1.2em;
  display: inline-flex;
}

.p-icon {
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.p-icon.more {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10ZM19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z' fill='rgba(255,255,255,1)'%3E%3C/path%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.p-size-selection {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.p-size-selection .p-size-selection-option {
  text-align: center;
  cursor: pointer;
  padding: 8px;
}

.p-size-selection .p-size-selection-option:hover {
  background-color: #ffffff1a;
}

/*# sourceMappingURL=index.15c66bcd.css.map */
