body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-image: url('../assets/bg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

.p-main {
    display: block;
    
    .p-top {
        display: flex;
        justify-content: space-between;
        user-select: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(3px);
        color: #ffffff;
        padding: 8px;
        
        .p-top-button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            align-items: center;
            box-sizing: border-box;
            background-color: transparent;
            color: #ffffff;
            font-family: 'Ubuntu', sans-serif;
            font-size: 14px;
            border: 1px solid transparent;
            border-radius: 5px;
            padding: 4px 8px;
            cursor: pointer;
            outline: none;
            
            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
        
        .p-logo {
            display: flex;
            align-items: center;
            font-family: 'Agdasima', sans-serif;
            font-size: 24px;
            font-weight: bold;
            line-height: 100%;
            text-shadow: 0 2px 2px black;
            padding-bottom: 2px;
            
            img {
                height: 24px;
                transform: rotate(35deg);
                margin-right: 6px;
                margin-bottom: -1px;
            }
        }
    }
    
    .p-canvas {
        display: block;
        user-select: none;
    }
    
    .p-explorer {
        position: fixed;
        box-sizing: border-box;
        padding: 8px;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 500px;
        max-height: 80vh;
        overflow-y: scroll;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(15px);
        font-family: 'Ubuntu', sans-serif;
        font-size: 12pt;
        color: #ffffff;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
        
        .p-logo {
            display: flex;
            margin: 0 auto;
            align-items: center;
            justify-content: center;
            font-family: 'Agdasima', sans-serif;
            font-size: 48px;
            font-weight: bold;
            line-height: 100%;
            text-shadow: 0 2px 2px black;
            padding-bottom: 2px;
            margin-bottom: 12px;
            
            img {
                height: 48px;
                transform: rotate(35deg);
                margin-right: 6px;
                margin-bottom: -1px;
            }
        }
        
        p {
            margin: 0 0 8px 0;
            padding: 0;
        }
        
        .p-explorer-puzzle {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 4px;
            
            &:nth-child(2n) {
                background-color: rgba(255, 255, 255, 0.1);
            }
            
            &:nth-child(3n), &:nth-child(1) {
                background-color: rgba(255, 255, 255, 0.3);
            }
            
            h4 {
                display: block;
                font-family: 'Agdasima', sans-serif;
                font-weight: bold;
                font-size: 24px;
                margin: 0 0 8px 0;
            }
            
            img {
                display: block;
                width: 100%;
                max-width: 150px;
                margin-right: 8px;
                border-radius: 8px;
            }
        }
    }
}

.p-button {
    display: inline-block;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 4px 8px;
    cursor: pointer;
    outline: none;
    
    &:hover {
        border: 1px solid white;
    }
    
    &.continue {
        border-color: blue;
        box-shadow: inset 0 0 5px deepskyblue;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
        
        &:hover {
            filter: brightness(150%);
        }
    }
}

i[class^="ri-"] {
    display: inline-flex;
    vertical-align: middle;
    margin-top: -0.2em;
    font-size: 1.2em;
    justify-content: center;
    align-items: center;
}

.p-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    
    &.more {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10ZM19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z' fill='rgba(255,255,255,1)'%3E%3C/path%3E%3C/svg%3E");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    
}

.p-size-selection {
    display: flex;
    align-items: center;
    justify-content: space-around;
    
    .p-size-selection-option {
        text-align: center;
        padding: 8px;
        cursor: pointer;
        
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
}